import {
  SET_ACTIVE_APP_ID,
  SET_DEVICE_LIST_SELECTED_VIEW,
  SET_DEVICE_SEARCH,
  SET_DEVICE_SEARCH_KEY,
  SET_DEVICE_SORTING_DIRECTION,
  SET_DEVICE_SORTING_FIELD,
} from '../../action/Device';

const initialState = {
  views: [],
  error: '',
  search: '',
  selectedView: { filters: [], operator: 'And' },
  sortingOption: { id: 'updatedAt' },
  searchKey: 'xId',
  activeAppId: '',
  direction: false,
};
const DeviceListFilterReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case SET_ACTIVE_APP_ID: {
      newState = {
        ...curState,
        activeAppId: action.payload.activeAppId,
      };
      break;
    }
   case SET_DEVICE_SORTING_FIELD:
      newState = {
        ...curState,
        sortingOption: action.payload.sortingOption,
      };
      break;
    case SET_DEVICE_SORTING_DIRECTION:
      newState = {
        ...curState,
        direction: action.payload.direction,
      };
      break;
    case SET_DEVICE_LIST_SELECTED_VIEW:
      newState = {
        ...curState,
        selectedView: action.payload.selectedView,
      };
      break;
    case SET_DEVICE_SEARCH:
      newState = {
        ...curState,
        search: action.payload,
      };
      break;
    case SET_DEVICE_SEARCH_KEY:
      newState = {
        ...curState,
        searchKey: action.payload,
      };
      break;
    default:
      newState = curState;
  }

  return newState;
};

export default DeviceListFilterReducer;
