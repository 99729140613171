export const FETCH_DEVICE_DETAIL = 'FETCH_DEVICE_DETAIL';
export const FETCH_DEVICE_DETAIL_FAILURE = 'FETCH_DEVICE_DETAIL_FAILURE';
export const FETCH_DEVICE_DETAIL_SUCCESS = 'FETCH_DEVICE_DETAIL_SUCCESS';
export const FETCH_DEVICE_LIST = 'FETCH_DEVICE_LIST';
export const FETCH_DEVICE_LIST_SUCCESS = 'FETCH_DEVICE_LIST_SUCCESS';
export const FETCH_DEVICE_LIST_FAILURE = 'FETCH_DEVICE_LIST_FAILURE';
export const RELOAD_DEVICE_LIST = 'RELOAD_DEVICE_LIST';
export const RESET_DEVICE_ITEMS = 'RESET_DEVICE_ITEMS';
export const RESET_DEVICE_DATAMAP = 'RESET_DEVICE_DATAMAP';
export const REMOVE_DEVICE_DETAIL = 'REMOVE_DEVICE_DETAIL';
export const SET_DEVICE_PAGE_INDEX = 'SET_DEVICE_PAGE_INDEX';
export const SET_DEVICE_PAGE_SIZE = 'SET_DEVICE_PAGE_SIZE';
export const SET_DEVICE_SEARCH = 'SET_DEVICE_SEARCH';
export const SET_DEVICE_SEARCH_KEY = 'SET_DEVICE_SEARCH_KEY';
export const UPDATE_DEVICE_LIST_ITEM = 'UPDATE_DEVICE_LIST_ITEM';
export const UPDATE_DEVICE_DETAIL = 'UPDATE_DEVICE_DETAIL';
export const SET_DEVICE_SORTING_DIRECTION = 'SET_DEVICE_SORTING_DIRECTION';
export const SET_DEVICE_SORTING_FIELD = 'SET_DEVICE_SORTING_FIELD';
export const SET_ACTIVE_APP_ID = 'SET_ACTIVE_APP_ID';
export const SET_DEVICE_LIST_SELECTED_VIEW = 'SET_DEVICE_LIST_SELECTED_VIEW';
export const FETCH_DEVICE_LIST_VIEWS = 'FETCH_DEVICE_LIST_VIEWS';
export const FETCH_DEVICE_LIST_VIEWS_SUCCESS = 'FETCH_DEVICE_LIST_VIEWS_SUCCESS';
export const FETCH_DEVICE_LIST_VIEWS_FAILURE = 'FETCH_DEVICE_LIST_VIEWS_FAILURE';

export const FETCH_DEVICE_PROFILE_ACTIVITY = 'FETCH_DEVICE_PROFILE_ACTIVITY';
export const FETCH_DEVICE_PROFILE_ACTIVITY_FAILURE = 'FETCH_DEVICE_PROFILE_ACTIVITY_FAILURE';
export const FETCH_DEVICE_PROFILE_ACTIVITY_SUCCESS = 'FETCH_DEVICE_PROFILE_ACTIVITY_SUCCESS';
export const RESET_DEVICE_PROFILE_ACTIVITY = 'RESET_DEVICE_PROFILE_ACTIVITY';
export const FETCH_NEXT_DEVICE_PROFILE_ACTIVITY = 'FETCH_NEXT_DEVICE_PROFILE_ACTIVITY';

